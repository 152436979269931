import React, { useEffect, useState, useRef } from "react";
import { Row, Col } from "react-flexbox-grid";
import { Button, SelectField, TextField } from "react-md";
import { Checkbox } from "@material-ui/core";
import UploadFileIcon from "../../../../../assets/icons/upload-file-icon.png";
import "../styles/index.css";
import FontIcon from "react-md/lib/FontIcons";
import CreateServiceUtil from "../services/CreateServiceUtil";
import SimpleBackdrop from "../helper/SimpleBackdrop";
import EventService from "../../../../../service/event/EventService";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import htmlToDraft from "html-to-draftjs";
import RangeSlider from "../helper/RangeSlider";

export const CreateService = (props) => {
  const [serviceMobileImage, setServiceMobileImage] = useState(null);
  const [serviceMobilePreview, setServiceMobilePreview] = useState("");
  const [serviceDesktopImage, setServiceDesktopImage] = useState(null);
  const [serviceDesktopPreview, setServiceDesktopPreview] = useState("");
  const [servicesTypeArr, setServicesTypeArr] = useState([]);
  const [servicesTypeObj, setServicesTypeObj] = useState([]);
  const [servicesSubTypeArr, setServicesSubTypeArr] = useState([]);
  const [servicesSubTypeObj, setServicesSubTypeObj] = useState([]);
  const [serviceToCreate, setServiceToCreate] = useState({});
  const [showBackdrop, setShowBackdrop] = useState(false);
  const [saveBtnEnabled, setSaveBtnEnabled] = useState(true);
  const [mode, setMode] = useState(0);
  const [loading, setLoading] = useState(false);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const createServiceUtil = new CreateServiceUtil();
  const serviceMobileImageInputRef = useRef(null);
  const serviceDesktopImageInputRef = useRef(null);

  useEffect(() => {
    if (props?.data?.mode == "view") {
      getServiceById(props.data.serviceId);
      setMode(2);
    } else if (props?.data?.mode == "update") {
      getServiceById(props.data.serviceId);
      setMode(3);
    } else {
      setServiceToCreate({
        ...serviceToCreate,
        ageRule: {
          isApplicable: false,
        },
        addressRequired: false,
      });
    }

    getServiceType();
  }, []);
  // useEffect(() => {
  //   if (
  //     serviceToCreate.serviceTypeId &&
  //     serviceToCreate.serviceSubtypeId &&
  //     serviceToCreate.isActive &&
  //     serviceToCreate.name &&
  //     serviceToCreate.name.trim() != "" &&
  //     serviceToCreate.serviceName &&
  //     serviceToCreate.serviceName.trim() != "" &&
  //     !(
  // serviceToCreate.descriptionLong == null ||
  // serviceToCreate.descriptionLong.trim() === "" ||
  // serviceToCreate.descriptionLong.trim() === "<p></p>" ||
  // serviceToCreate.descriptionLong.trim() === "<p><br></p>"
  //     ) &&
  //     serviceToCreate.description &&
  //     serviceToCreate.description.trim() != "" &&
  //     serviceToCreate.addressRequired &&
  //     serviceToCreate.canBeBookedFor &&
  //     serviceToCreate.imageMobile
  //   ) {
  //     setSaveBtnEnabled(true);
  //   } else {
  //     setSaveBtnEnabled(false);
  //   }
  // });
  useEffect(() => {
    let dropdownObj = servicesTypeArr.map((e) => {
      return {
        label: e.name,
        value: e.id,
      };
    });
    setServicesTypeObj(dropdownObj);
  }, [servicesTypeArr]);
  useEffect(() => {
    let dropdownObj = servicesSubTypeArr.map((e) => {
      return {
        label: e.name,
        value: e.id,
      };
    });
    setServicesSubTypeObj(dropdownObj);
  }, [servicesSubTypeArr]);

  useEffect(() => {
    if (serviceMobileImage != null) {
      setShowBackdrop(true);
      uploadImage(serviceMobileImage)
        .then((e) => {
          setServiceMobilePreview(e);
          setServiceToCreate({
            ...serviceToCreate,
            imageMobile: e,
          });
          setTimeout(() => {
            setShowBackdrop(false);
          }, 500);
        })
        .catch((err) => {
          setTimeout(() => {
            setShowBackdrop(false);
            EventService.emit("showError", {
              message: `Error while uploading the plan mobile image: ${err}`,
            });
          }, 500);
        });
    }
  }, [serviceMobileImage]);
  useEffect(() => {
    if (serviceDesktopImage != null) {
      setShowBackdrop(true);
      uploadImage(serviceDesktopImage)
        .then((e) => {
          setServiceDesktopPreview(e);
          setServiceToCreate({
            ...serviceToCreate,
            imageDesktop: e,
          });
          setTimeout(() => {
            setShowBackdrop(false);
          }, 500);
        })
        .catch((err) => {
          setTimeout(() => {
            setShowBackdrop(false);
            EventService.emit("showError", {
              message: `Error while uploading the plan desktop image: ${err}`,
            });
          }, 500);
        });
    }
  }, [serviceDesktopImage]);

  const DEFAULT_MIN_AGE = 20;
  const DEFAULT_MAX_AGE = 60;

  let getServiceType = () => {
    let onResponse = (res) => {
      setServicesTypeArr((prev) => {
        const updatedState = [...res];
        return updatedState;
      });
    };
    let onError = (err) => {
      EventService.emit("showError", {
        message: `Error: ${err?.message}`,
      });
    };
    createServiceUtil.getServiceType(true, onResponse, onError);
  };
  let getServiceSubType = (id) => {
    let onResponse = (res) => {
      setServicesSubTypeArr((prev) => {
        const updatedState = [...res];
        return updatedState;
      });
    };
    let onError = (err) => {
      EventService.emit("showError", {
        message: `Error: ${err?.message}`,
      });
    };
    createServiceUtil.getServiceSubType(id, true, onResponse, onError);
  };
  const getServiceById = (id) => {
    let onResponse = (res) => {
      getServiceSubType(res.serviceTypeId);
      let ser = {
        ...res,
        isActive: res.isActive == true ? "true" : "false",
      };
      setServiceToCreate(ser);
      setServiceMobilePreview(ser?.imageMobile);
      setServiceDesktopPreview(ser?.imageDesktop);
      if (!res.descriptionLong || typeof res.descriptionLong !== "string") {
        console.error("Invalid HTML content:", res.descriptionLong);
        return;
      }

      let contentBlock = htmlToDraft(res.descriptionLong);
      if (contentBlock) {
        let contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks
        );
        let editorState = EditorState.createWithContent(contentState);
        setEditorState(editorState);
      }
    };
    let onError = (err) => {
      EventService.emit("showError", {
        message: `Error: ${err?.message}`,
      });
    };
    createServiceUtil.getServiceById(id, onResponse, onError);
  };

  let ADDRESS_REQUIRED_OPTIONS = [
    {
      label: "Yes",
      value: "true",
    },
    {
      label: "No",
      value: "false",
    },
  ];
  let SERVICE_STATUS_OPTIONS = [
    {
      label: "Active",
      value: "true",
    },
    {
      label: "Inactive",
      value: "false",
    },
  ];
  let CAN_BE_BOOKED_FOR_OPTIONS = [
    {
      label: "Male",
      value: "Male",
    },
    {
      label: "Female",
      value: "Female",
    },
    {
      label: "Both",
      value: "Both",
    },
  ];

  const serviceMobileUploadClick = () => {
    if (serviceMobileImageInputRef.current) {
      serviceMobileImageInputRef.current.click();
    }
  };

  const serviceMobileImageSelected = (event) => {
    const file = event.target.files[0];

    if (!file) {
      console.log("No file selected or file picker was canceled");
      return;
    }

    const maxSize = 20 * 1024 * 1024; // 20MB
    if (file.size > maxSize) {
      EventService.emit("showError", {
        message: "File size cannot be more than 20MB",
      });
      return;
    }
    const img = new Image();
    img.onload = function () {
      if (this.width !== 1080 || this.height !== 1080) {
        EventService.emit("showError", {
          message: "Image dimensions must be 1080x1080 pixels",
        });
        return;
      }
      setServiceMobileImage(file);
    };
    img.src = URL.createObjectURL(file);
  };
  const serviceDesktopUploadClick = () => {
    if (serviceDesktopImageInputRef.current) {
      serviceDesktopImageInputRef.current.click();
    }
  };

  const serviceDesktopImageSelected = (event) => {
    const file = event.target.files[0];

    if (!file) {
      console.log("No file selected or file picker was canceled");
      return;
    }

    const maxSize = 20 * 1024 * 1024; // 20MB
    if (file.size > maxSize) {
      EventService.emit("showError", {
        message: "File size cannot be more than 20MB",
      });
      return;
    }
    const img = new Image();
    img.onload = function () {
      if (this.width !== 1080 || this.height !== 1080) {
        EventService.emit("showError", {
          message: "Image dimensions must be 1080x1080 pixels",
        });
        return;
      }
      setServiceDesktopImage(file);
    };
    img.src = URL.createObjectURL(file);
  };

  let isServiceValid = () => {
    console.log("pr- body=", serviceToCreate);

    if (
      serviceToCreate.serviceTypeId == null ||
      serviceToCreate.serviceTypeId == ""
    ) {
      EventService.emit("showError", {
        message: "Please fill the service type",
      });
      return false;
    } else if (
      serviceToCreate.serviceSubtypeId == null ||
      serviceToCreate.serviceSubtypeId == ""
    ) {
      EventService.emit("showError", {
        message: "Please fill the Service subtype",
      });
      return false;
    } else if (
      serviceToCreate.isActive == null ||
      serviceToCreate.isActive == ""
    ) {
      EventService.emit("showError", {
        message: "Please select the service status",
      });
      return false;
    } else if (
      serviceToCreate.name == null ||
      serviceToCreate.name.trim() == ""
    ) {
      EventService.emit("showError", {
        message: "Please fill the service code",
      });
      return false;
    } else if (
      serviceToCreate.serviceName == null ||
      serviceToCreate.serviceName.trim() == ""
    ) {
      EventService.emit("showError", {
        message: "Please fill the service name",
      });
      return false;
    } else if (
      serviceToCreate.imageMobile == null ||
      serviceToCreate.imageMobile == ""
    ) {
      EventService.emit("showError", {
        message: "Please select an image for the mobile view",
      });
      return false;
    } else if (
      serviceToCreate.description == null ||
      serviceToCreate.description.trim() == ""
    ) {
      EventService.emit("showError", {
        message: "Please enter a short description",
      });
      return false;
    } else if (
      serviceToCreate.descriptionLong == null ||
      serviceToCreate.descriptionLong.trim() === "" ||
      serviceToCreate.descriptionLong.trim() === "<p></p>" ||
      serviceToCreate.descriptionLong.trim() === "<p><br></p>"
    ) {
      EventService.emit("showError", {
        message: "Please enter long description",
      });
      return false;
    } else if (
      serviceToCreate.canBeBookedFor == null ||
      serviceToCreate.canBeBookedFor == ""
    ) {
      EventService.emit("showError", {
        message: "Please select can be booked for field",
      });
      return false;
    } else {
      return true;
    }
  };
  let saveNewService = () => {
    if (isServiceValid()) {
      mode == 3
        ? updateServiceAPI(serviceToCreate)
        : createServiceAPI(serviceToCreate);
    }
  };
  const createServiceAPI = (body) => {
    setLoading(true);
    body = {
      ...body,
      isActive: body.isActive == "true" ? true : false,
    };
    let onResponse = (res) => {
      EventService.emit("showError", {
        message: `Service created successfully`,
      });
      setLoading(false);
      exitPage();
    };
    let onError = (err) => {
      EventService.emit("showError", {
        message: `Error: ${err?.message}`,
      });
      setLoading(false);
    };
    createServiceUtil.createService(body, onResponse, onError);
  };
  const updateServiceAPI = (body) => {
    setLoading(true);
    body = {
      ...body,
      isActive: body.isActive == "true" ? true : false,
    };
    let onResponse = (res) => {
      EventService.emit("showError", {
        message: `Service updated successfully`,
      });
      setLoading(false);
      exitPage();
    };
    let onError = (err) => {
      EventService.emit("showError", {
        message: `Error: ${err?.message}`,
      });
      setLoading(false);
    };
    createServiceUtil.updateService(
      props.data.serviceId,
      body,
      onResponse,
      onError
    );
  };
  const uploadImage = (file) => {
    return new Promise((resolve, reject) => {
      const data = new FormData();
      data.append("file", file, file.name);

      createServiceUtil.uploadImg(
        data,
        (res) => {
          resolve(res.publicUrl);
        },
        (err) => {
          reject(err);
        }
      );
    });
  };
  const _onEditorStateChange = (editorState) => {
    let body = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    setServiceToCreate({
      ...serviceToCreate,
      descriptionLong: body,
    });
    setEditorState(editorState);
  };
  const setAge = (min, max) => {
    setServiceToCreate({
      ...serviceToCreate,
      ageRule: {
        isApplicable: true,
        fromAge: min,
        toAge: max,
      },
    });
  };
  const exitPage = () => {
    setServiceMobileImage(null);
    setServiceMobilePreview("");
    setServiceDesktopImage(null);
    setServiceDesktopPreview("");
    setServicesTypeArr([]);
    setServicesTypeObj([]);
    setServicesSubTypeArr([]);
    setServicesSubTypeObj([]);
    setServiceToCreate({});
    setShowBackdrop(false);
    setSaveBtnEnabled(false);
    setLoading(false);
    setEditorState(EditorState.createEmpty());

    props.setView(1);
  };

  return (
    <div className="mp-page-padding">
      {console.log("pr- service=", serviceToCreate)}
      <Row>
        <Col xs={12} sm={12} md={12} lg={12}>
          <div className="mp-two-side-view">
            <div className="mp-heading-with-back-button">
              <div
                onClick={() => {
                  exitPage();
                }}
              >
                <FontIcon
                  style={{
                    fontSize: "28px",
                    paddingTop: "2px",
                    paddingRight: "12px",
                    cursor: "pointer",
                  }}
                >
                  arrow_back
                </FontIcon>
              </div>
              <h2>
                {mode == 2
                  ? `View service`
                  : mode == 3
                  ? `Update service`
                  : `Create new service`}
              </h2>
            </div>
            <div className="mp-buttons-container">
              <div className="mp-buttons-right-alignment">
                {mode == 2 ? (
                  <></>
                ) : (
                  <Button
                    raised
                    disabled={!saveBtnEnabled || loading}
                    className={
                      saveBtnEnabled && loading == false
                        ? "mp-button-save-enabled"
                        : "mp-button-save-disabled"
                    }
                    onClick={() => {
                      saveNewService();
                    }}
                  >
                    {mode == 3 ? "Update" : "Save"}
                  </Button>
                )}

                <Button
                  raised
                  disabled={loading}
                  className="mp-button-cancel"
                  onClick={() => {
                    exitPage();
                  }}
                >
                  {"Cancel"}
                </Button>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <br />
      <br />
      <br />
      <div className="mp-create-service-container">
        <Row>
          <Col xs={12} sm={3} md={3} lg={3}>
            <div className="mp-create-service-field-container">
              <div className="mp-label">
                {`Service type`}
                <label className="mp-label-red"> *</label>
              </div>
              <div>
                <SelectField
                  placeholder="Select service type"
                  disabled={mode == 2 || mode == 3}
                  className="mp-select-field"
                  menuItems={servicesTypeObj}
                  value={serviceToCreate?.serviceTypeId}
                  onChange={(e) => {
                    setServiceToCreate({
                      ...serviceToCreate,
                      serviceTypeId: Number(e),
                      serviceSubtypeId: "",
                    });
                    setServicesSubTypeObj([]);
                    getServiceSubType(Number(e));
                  }}
                />
              </div>
            </div>
          </Col>
          <Col xs={12} sm={1} md={1} lg={1}>
            <div style={{ height: "20px" }}></div>
          </Col>
          <Col xs={12} sm={3} md={3} lg={3}>
            <div className="mp-create-service-field-container">
              <div className="mp-label">
                {`Service subtype`} <label className="mp-label-red"> *</label>
              </div>
              <div>
                <SelectField
                  placeholder="Select service subtype"
                  disabled={mode == 2 || mode == 3}
                  className="mp-select-field"
                  menuItems={servicesSubTypeObj}
                  value={serviceToCreate?.serviceSubtypeId}
                  onChange={(e) => {
                    setServiceToCreate({
                      ...serviceToCreate,
                      serviceSubtypeId: Number(e),
                    });
                  }}
                />
              </div>
            </div>
          </Col>
          <Col xs={12} sm={1} md={1} lg={1}>
            <div style={{ height: "20px" }}></div>
          </Col>
          <Col xs={12} sm={3} md={3} lg={3}>
            <div className="mp-create-service-field-container">
              <div className="mp-label">
                {`Service status`}
                <label className="mp-label-red"> *</label>
              </div>
              <div>
                <SelectField
                  placeholder="Select"
                  disabled={mode == 2}
                  className="mp-select-field"
                  menuItems={SERVICE_STATUS_OPTIONS}
                  value={serviceToCreate?.isActive}
                  onChange={(e) => {
                    setServiceToCreate({
                      ...serviceToCreate,
                      isActive: e,
                    });
                  }}
                />
              </div>
            </div>
          </Col>
        </Row>
        <br />
        <Row>
          <Col xs={12} sm={3} md={3} lg={3}>
            <div className="mp-create-service-field-container">
              <div className="mp-label">
                {`Service code (50 characters)`}
                <label className="mp-label-red"> *</label>
              </div>
              <div>
                <TextField
                  disabled={mode == 2}
                  lineDirection="left"
                  placeholder="Enter service"
                  value={
                    serviceToCreate?.name ? `${serviceToCreate?.name}` : ""
                  }
                  onChange={(e) => {
                    if (e.length <= 50) {
                      setServiceToCreate({ ...serviceToCreate, name: e });
                    }
                  }}
                />
              </div>
            </div>
          </Col>
          <Col xs={12} sm={1} md={1} lg={1}>
            <div style={{ height: "20px" }}></div>
          </Col>
          <Col xs={12} sm={3} md={3} lg={3}>
            <div className="mp-create-service-field-container">
              <div className="mp-label">
                {`Service name (50 characters)`}
                <label className="mp-label-red"> *</label>
              </div>
              <div>
                <TextField
                  lineDirection="left"
                  value={
                    serviceToCreate?.serviceName
                      ? `${serviceToCreate?.serviceName}`
                      : ""
                  }
                  disabled={mode == 2}
                  placeholder="This will be displayed on the app"
                  onChange={(e) => {
                    if (e.length <= 50) {
                      setServiceToCreate({
                        ...serviceToCreate,
                        serviceName: e,
                      });
                    }
                  }}
                />
              </div>
            </div>
          </Col>
        </Row>
        <br />
        <Row>
          <Col xs={12} sm={3} md={3} lg={3}>
            <div className="mp-create-service-field-container">
              <div className="mp-label">
                {`Image (Mobile view)`}
                <label className="mp-label-red"> *</label>
              </div>
              <div className="mp-image-requirements">{`(Max file size: 20mb, dimension 1080*1080px)`}</div>
              <Button
                raised
                disabled={mode == 2}
                className="mp-button-upload"
                onClick={() => {
                  serviceMobileUploadClick();
                }}
              >
                {"Upload"}
              </Button>
              <input
                type="file"
                accept="image/png, image/jpeg"
                ref={serviceMobileImageInputRef}
                style={{ display: "none" }}
                onChange={serviceMobileImageSelected}
              />
              <Col xs={12} sm={5} md={4} lg={4}>
                {serviceMobilePreview && serviceMobilePreview != "" && (
                  <>
                    <div
                      style={{
                        display: "inline-block",
                        border: "1px solid black",
                        marginTop: "15px",
                        marginLeft: "-6px",
                      }}
                    >
                      <img
                        src={serviceMobilePreview}
                        alt="Selected Preview"
                        style={{
                          width: "100px",
                          height: "auto",
                          display: "block",
                        }}
                      />
                    </div>
                  </>
                )}
              </Col>
            </div>
          </Col>
          <Col xs={12} sm={1} md={1} lg={1}>
            <div style={{ height: "20px" }}></div>
          </Col>
          <Col xs={12} sm={3} md={3} lg={3}>
            <div className="mp-create-service-field-container">
              <div className="mp-label">{`Image (Desktop view)`}</div>
              <div className="mp-image-requirements">{`(Max file size: 20mb, dimension 1080*1080px)`}</div>
              <Button
                raised
                disabled={mode == 2}
                className="mp-button-upload"
                onClick={() => {
                  serviceDesktopUploadClick();
                }}
              >
                {"Upload"}
              </Button>
              <input
                type="file"
                accept="image/png, image/jpeg"
                ref={serviceDesktopImageInputRef}
                style={{ display: "none" }}
                onChange={serviceDesktopImageSelected}
              />
              <Col xs={12} sm={5} md={4} lg={4}>
                {serviceDesktopPreview && serviceDesktopPreview != "" && (
                  <>
                    <div
                      style={{
                        display: "inline-block",
                        border: "1px solid black",
                        marginTop: "15px",
                        marginLeft: "-6px",
                      }}
                    >
                      <img
                        src={serviceDesktopPreview}
                        alt="Selected Preview"
                        style={{
                          width: "100px",
                          height: "auto",
                          display: "block",
                        }}
                      />
                    </div>
                  </>
                )}
              </Col>
            </div>
          </Col>
        </Row>

        <br />
        <Row>
          <Col xs={12} sm={12} md={12} lg={12}>
            <div className="mp-create-service-field-container">
              <div className="mp-label">
                {`Service short description (50 characters)`}
                <label className="mp-label-red"> *</label>
              </div>
              <div>
                <TextField
                  disabled={mode == 2}
                  value={
                    serviceToCreate?.description
                      ? `${serviceToCreate?.description}`
                      : ""
                  }
                  lineDirection="left"
                  // placeholder="Enter short description for the service"
                  onChange={(e) => {
                    if (e.length <= 50) {
                      setServiceToCreate({
                        ...serviceToCreate,
                        description: e,
                      });
                    }
                  }}
                />
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12}>
            <div className="mp-create-service-field-container">
              <div className="mp-label">
                {`Service long description`}
                <label className="mp-label-red"> *</label>
              </div>
              <div
                style={{ borderBottom: "2px solid #E4E4E4", marginTop: "10px" }}
              >
                <Editor
                  // toolbarClassName={showToolbar ? null : "toolBar-hidden"}
                  // toolbarClassName={showToolbar ? null : " toolBar-hidden-pn"}

                  readOnly={mode == 2}
                  editorState={editorState}
                  wrapperClassName=""
                  editorClassName=""
                  // hashtag={{
                  //   separator: " ",
                  //   trigger: "#",
                  // }}
                  onEditorStateChange={_onEditorStateChange}
                  stripPastedStyles={true}
                  // placeholder={
                  //   <label>{`Please enter service long description`}</label>
                  // }
                />
              </div>
            </div>
          </Col>
        </Row>
        <br />
        <Row>
          <Col xs={12} sm={3} md={3} lg={3}>
            <div className="mp-create-service-field-container">
              <div className="mp-label">
                {`Can be booked for`}
                <label className="mp-label-red"> *</label>
              </div>
              <div>
                <SelectField
                  placeholder="Male/Female/Both"
                  disabled={mode == 2}
                  className="mp-select-field"
                  menuItems={CAN_BE_BOOKED_FOR_OPTIONS}
                  value={serviceToCreate?.canBeBookedFor}
                  onChange={(e) => {
                    setServiceToCreate({
                      ...serviceToCreate,
                      canBeBookedFor: e,
                    });
                  }}
                />
              </div>
            </div>
          </Col>
          <Col xs={12} sm={1} md={1} lg={1}>
            <div style={{ height: "20px" }}></div>
          </Col>
          <Col xs={12} sm={3} md={3} lg={3}>
            <div className="mp-create-service-field-container">
              <div className="mp-label">{`Age rule applicable`}</div>
              <div className="mp-age-checkbox">
                <div>
                  <Checkbox
                    id="mp-checkbox"
                    name="mp-age-rule-applicable"
                    disabled={mode == 2}
                    color={mode == 2 ? "default" : "secondary"}
                    style={{ marginLeft: -13, marginTop: 3 }}
                    checked={
                      serviceToCreate?.ageRule?.isApplicable != null
                        ? serviceToCreate.ageRule.isApplicable
                          ? true
                          : false
                        : false
                    }
                    onChange={(value, proxy) => {
                      serviceToCreate?.ageRule?.isApplicable == true
                        ? setServiceToCreate({
                            ...serviceToCreate,
                            ageRule: {
                              isApplicable: false,
                            },
                          })
                        : setServiceToCreate({
                            ...serviceToCreate,
                            ageRule: {
                              isApplicable: true,
                              fromAge: DEFAULT_MIN_AGE,
                              toAge: DEFAULT_MAX_AGE,
                            },
                          });
                    }}
                  />
                </div>
                <div>
                  {serviceToCreate?.ageRule?.isApplicable == true && (
                    <>
                      <div className="mp-slider-container">
                        {mode == 2 ? (
                          <div style={{ height: "16px" }}></div>
                        ) : (
                          <RangeSlider
                            min={
                              serviceToCreate.ageRule.fromAge
                                ? serviceToCreate.ageRule.fromAge
                                : DEFAULT_MIN_AGE
                            }
                            max={
                              serviceToCreate.ageRule.toAge
                                ? serviceToCreate.ageRule.toAge
                                : DEFAULT_MAX_AGE
                            }
                            rangeMin={0}
                            rangeMax={150}
                            setAge={setAge}
                          />
                        )}
                      </div>
                      <div className="mp-age-text-container">
                        {`Between ${serviceToCreate?.ageRule?.fromAge} and ${serviceToCreate?.ageRule?.toAge}`}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </Col>
          <Col xs={12} sm={1} md={1} lg={1}>
            <div style={{ height: "20px" }}></div>
          </Col>
          <Col xs={12} sm={3} md={3} lg={3}>
            <div className="mp-create-service-field-container">
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div>
                  <Checkbox
                    id={`mp-create-service-checkbox-address`}
                    name={`mp-create-service-checkbox-address`}
                    disabled={mode == 2}
                    color={mode == 2 ? "default" : "secondary"}
                    checked={
                      serviceToCreate.addressRequired != null
                        ? serviceToCreate.addressRequired
                        : false
                    }
                    style={{ marginLeft: "-10px" }}
                    onChange={(event) => {
                      if (serviceToCreate.addressRequired == true) {
                        setServiceToCreate({
                          ...serviceToCreate,
                          addressRequired: false,
                        });
                      } else if (serviceToCreate.addressRequired == false) {
                        setServiceToCreate({
                          ...serviceToCreate,
                          addressRequired: true,
                        });
                      }
                    }}
                  />
                </div>
                <div className="mp-plan-label-checkbox">
                  {`Address required`}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      {showBackdrop ? <SimpleBackdrop /> : <></>}
    </div>
  );
};

export default CreateService;
